import React, { useEffect } from 'react';
import { navigate, graphql } from 'gatsby';
import { Para, Button, Display, Flex, Box } from 'workspace-core-ui';
import styled from 'styled-components';
import css from '@styled-system/css';
import { useAppSelector, useAppDispatch } from '@hooks/redux-hooks';
import Layout from '@containers/Layout';
import useTranslation from '@hooks/useTranslation';
import { setCurrentRoute } from '@slices/routeSlice';
import BodyWrapper from '@components/BodyWrapper';
import { motion } from 'framer-motion';
import { gameHasFinished, setHeaderType } from '@slices/gameStateSlice';
import gameConfig from '@content/gameconfig';
import useCustomReducedMotion from '@hooks/useCustomReducedMotion';
import AnimatedIntroImageWrapper from '@components/AnimatedIntroImageWrapper';
import getSymbol from '@utils/getSymbol';
import { logItem } from '@slices/loggingSlice';
import useNavigateLog from '@hooks/useNavigateLog';

const CorrectnessIndicator = ({
  claimsAnsweredCorrectly = 0,
  totalClaims = 0,
  t,
}) => {
  // TODO: not all languages follow the of/correct pattern, use placeholders later like {score} {interstice} {total} on airtable itself
  const indicatorType = gameConfig.Correctness_Indicator;
  if (!indicatorType) {
    return null;
  }

  if (indicatorType === 'Use Percent') {
    return (
      <Para>
        {Math.round((claimsAnsweredCorrectly / totalClaims) * 100) | 0}%{' '}
        {t('End Screen Score Correct')}
      </Para>
    );
  }
  if (indicatorType === 'Use Of') {
    return (
      <Para>
        {claimsAnsweredCorrectly} {t('Claim Score Interstice')} {totalClaims}{' '}
        {t('End Screen Score Correct')}
      </Para>
    );
  }
  if (indicatorType === 'Use Slash') {
    return (
      <Para>
        {claimsAnsweredCorrectly}/{totalClaims} {t('End Screen Score Correct')}
      </Para>
    );
  }
  return null;
};

const PlayAgainButton = styled(Button)(
  css({
    alignSelf: 'center',
  }),
);

const springTransition = {
  type: 'spring',
  damping: 10,
  stiffness: 500,
  delay: 0.2,
};

const CenterContentWrapper = styled(Flex)({
  isolation: 'isolate',
  mt: 2,
  flex: 0.5,
  flexDirection: 'column',
});

const BottomContentWrapper = styled(Flex)({
  isolation: 'isolate',
  flex: 1,
  flexDirection: 'column',
  alignSelf: 'center',
});

const CenterCircle = styled(Box).attrs({
  as: motion.div,
})(
  css({
    pt: '100%',
    height: 'auto',
    width: '100%',
    position: 'fixed',
    borderRadius: '50%',
    top: '50%',
    backgroundColor: 'primaryDark',
    transform: 'translateY(-50%)',
    zIndex: -1,
  }),
);

const EndPage = ({ data }) => {
  useNavigateLog({ questionName: 'end' });
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { claimsAnsweredCorrectly, currentLevel, totalClaims } = useAppSelector(
    s => s.levelling,
  );
  const endSymbol = getSymbol(data.endPageSymbols);

  useEffect(() => {
    dispatch(setCurrentRoute({ compareAgainst: 'end', findBy: 'name' }));
    dispatch(setHeaderType({ headerType: 'shareable' }));
    dispatch(gameHasFinished());
  }, [dispatch]);

  return (
    <Layout>
      <BodyWrapper overflow="hidden" backgroundColor="primary">
        <Flex flex={1} />
        <CenterContentWrapper>
          {endSymbol ? (
            <AnimatedIntroImageWrapper
              imageData={endSymbol.data}
              imageType={endSymbol.type}
            />
          ) : (
            <Flex flex={0.5} />
          )}
          <Para mt={3} mb={2} variant="p1">
            {t('End Title')}
          </Para>
          <Display>
            {t(`Level`)} {currentLevel}!
          </Display>
          <CorrectnessIndicator
            t={t}
            claimsAnsweredCorrectly={claimsAnsweredCorrectly}
            totalClaims={totalClaims}
          />
        </CenterContentWrapper>
        <BottomContentWrapper>
          <PlayAgainButton
            data-cy="play-again"
            onPress={() => {
              dispatch(
                logItem({
                  collection_name: 'events',
                  event_type: 'click',
                  location: window?.location?.pathname,
                  target: 'play-again-button',
                }),
              );
              navigate('/', {
                state: { cameFromEnd: true },
              });
            }}
          >
            <Para>{t('Play Again')}</Para>
          </PlayAgainButton>
        </BottomContentWrapper>
        <CenterCircle
          initial={{
            scale: useCustomReducedMotion() ? 1 : 0.2,
            y: '-50%',
          }}
          transition={springTransition}
          animate={{
            scale: 1,
          }}
        />
      </BodyWrapper>
    </Layout>
  );
};

export const query = graphql`
  query endPageImage {
    endPageSymbols: allAirtable(
      filter: {
        table: { eq: "Game Elements" }
        data: { Name: { eq: "End Title" } }
      }
    ) {
      ...SvgGetFragment
      ...GatsbyImageGetFragmentNoPlaceholder
    }
  }
`;

export default EndPage;
